import { Box, Tooltip } from "@mui/material";
import { Button } from "@samdesk/components/Button";

interface Props {
	action: () => void;
	disabled: boolean;
	label: string;
	tooltipMessage: string;
}

const EverbridgeIntegrationButton = ({
	action,
	disabled,
	label,
	tooltipMessage,
}: Props) => {
	return (
		<Tooltip
			disableHoverListener={!disabled}
			disableFocusListener={!disabled}
			disableTouchListener={!disabled}
			placement="top"
			title={tooltipMessage}
		>
			<Box sx={everbridgeIntegrationButtonBoxSx}>
				<Button disabled={disabled} onClick={action} label={label} />
			</Box>
		</Tooltip>
	);
};

export default EverbridgeIntegrationButton;

/** Styles */

const everbridgeIntegrationButtonBoxSx = {
	alignSelf: "end",
	width: "fit-content",
};

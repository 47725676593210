import { useQuery } from "@apollo/client";
import { Button } from "@samdesk/components/Button";
import { Form } from "@samdesk/components/Form";
import { Modal } from "@samdesk/components/Modal";
import { Stack } from "@samdesk/components/Stack";
import { Text } from "@samdesk/components/Text";
import { ReactElement, useState } from "react";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import { GenericIntegrationInput } from "./GenericIntegrationInput";
import { Integration, IntegrationResult } from "./Integration";

interface GenericIntegrationModalProps {
	integration: Integration;
	open: boolean;
	onClose: () => void;
	integrationGID?: string;
}

export function GenericIntegrationModal({
	integration,
	open,
	onClose,
	integrationGID,
}: GenericIntegrationModalProps): ReactElement {
	const [isDirty, setIsDirty] = useState(false);
	const [isValid, setIsValid] = useState(false);

	const isCreate = !integrationGID;
	const { data, loading } = useQuery(integration.actions.get, {
		variables: { gid: integrationGID as string }, // TODO: how do we avoid casting here?
		skip: isCreate || !open,
	});

	const defaultValues = getDefaultValues(integration.fields, data);

	return (
		<Modal
			title={
				isCreate ?
					`Create ${integration.name} Integration`
				:	`Update ${integration.name} Integration`
			}
			open={open}
			onRequestClose={onClose}
		>
			{loading ?
				<CenteredCircularProgress />
			:	<Form
					onSubmit={(formData) => {
						alert(JSON.stringify(formData, undefined, 2));
						onClose();
					}}
					defaultValues={defaultValues}
					onStateChange={({ isValid, isDirty }) => {
						setIsValid(isValid);
						setIsDirty(isDirty);
					}}
				>
					<Stack>
						{integration.fields.map((field) => (
							<GenericIntegrationInput
								field={field}
								integrationGID={integrationGID}
								key={field.name}
							/>
						))}
						<Text>Form is valid: {isValid ? "Yes" : "No"}</Text>
						<Text>Form is dirty: {isDirty ? "Yes" : "No"}</Text>
						<Button submit label={isCreate ? `Create` : `Update`} />
					</Stack>
				</Form>
			}
		</Modal>
	);
}

function getDefaultValues(
	fields: Integration["fields"],
	data?: IntegrationResult
) {
	const defaultValues: { [key: string]: string } = {};

	fields.forEach((field) => {
		defaultValues[field.name] = data?.integration?.[field.name] || "";
	});

	return defaultValues;
}

import { Box } from "@mui/material";
import layout from "common/layoutDefinitions";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import theme from "theme";

import Menu from "./Menu";
import NavTabs from "./NavTabs";
import Search from "./Search";
import { Stack } from "@samdesk/components/Stack";
import samdeskLogo from "images/samdeskLogo.png";

const NavBar = () => {
	const [visibleTabs, setVisibleTabs] = useState(0);

	const updateVisibleTabs = useMemo(
		() =>
			debounce(() => {
				const navMenuItems = 150;
				const tabDropdown = 50;
				const samdeskLogo = 60;
				const buffer = 20;
				const tabWidth = 215;
				const availableSpace =
					innerWidth - navMenuItems - tabDropdown - samdeskLogo - buffer;
				const visibleTabs = Math.floor(availableSpace / tabWidth);

				setVisibleTabs(visibleTabs <= 0 ? 1 : visibleTabs); // Always show at least one tab
			}, 200),
		[setVisibleTabs]
	);

	useEffect(() => {
		updateVisibleTabs();
	}, [updateVisibleTabs]);

	useEffect(() => {
		window.addEventListener("resize", updateVisibleTabs);

		return () => window.removeEventListener("resize", updateVisibleTabs);
	}, [updateVisibleTabs]);

	return (
		<Box sx={navBarContainerSx}>
			<Box sx={tabsWrapperSx}>
				<NavTabs visibleTabs={visibleTabs} />
			</Box>
			<Stack direction="row">
				<Search />
				<Box sx={actionsWrapper}>
					<Stack direction="row" alignItems="center">
						<img src={samdeskLogo} style={samdeskLogoStyle} />
						<Menu />
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default NavBar;

/** STYLES */

const samdeskLogoStyle = {
	width: "calc(var(--space-large) + var(--space-smallest))",
	height: "calc(var(--space-large)",
};

const actionsWrapper = {
	border: "1px solid var(--color-border)",
	paddingLeft: "var(--space-base)",
	marginBottom: "var(--space-small)",
	borderRadius: "var(--radius-base)",
};

const navBarContainerSx = {
	alignItems: "flex-end",
	backgroundColor: "background.default",
	borderBottom: `${layout.navBar.border}px solid ${theme.palette.border.main}`,
	display: "flex",
	height: `${layout.navBar.height}px`,
	justifyContent: "space-between",
	padding:
		"0 var(--space-base) 0 calc(var(--space-larger) + var(--space-small))",
	position: "relative",
};

const tabsWrapperSx = {
	display: "flex",
};

import { graphql } from "gql/apollo";

export const ListIntegrations = graphql(/* GraphQL */ `
	query ListEverbridgeIntegrations($params: ListEverbridgeIntegrationsParams!) {
		listEverbridgeIntegrations(params: $params) {
			_id
			name
			url
		}
	}
`);

import { InputText } from "@samdesk/components/InputText";
import { ReactElement } from "react";
import { Integration } from "./Integration";

interface GenericIntegrationInputProps {
	field: Integration["fields"][number];
	integrationGID?: string;
}

export function GenericIntegrationInput({
	field,
	integrationGID,
}: GenericIntegrationInputProps): ReactElement {
	const isCreate = !integrationGID;
	const required = isCreate ? field.required.create : field.required.update;

	const rules =
		required ?
			{
				required: "This field is required",
			}
		:	{};

	switch (field.type) {
		case "string":
			return <InputText name={field.name} label={field.label} rules={rules} />;

		default:
			return <></>;
	}
}

import Breadcrumbs from "routes/settings/Breadcrumbs";
import SettingsPageTitle from "ui-library/SettingsPageTitle";

import { useQuery } from "@apollo/client";
import { Button } from "@samdesk/components/Button";
import {
	createColumnHelper,
	DataTablePrototype,
} from "@samdesk/components/DataTablePrototype";
import { Stack } from "@samdesk/components/Stack";
import { Text } from "@samdesk/components/Text";
import { DeepKeys } from "@tanstack/table-core";
import { useMemo, useState } from "react";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import useRequiresAdmin from "utils/useRequiresAdmin";
import { GenericIntegrationModal } from "./GenericIntegrationModal";
import { Integration } from "./Integration";

type PlaceholderType = {
	_id: string;
	name: string;
	// gid: GID;
	url: string;
};

interface GenericListPageProps {
	integration: Integration;
}

export function GenericListPage({ integration }: GenericListPageProps) {
	const [modalOpen, setModalOpen] = useState(false);
	const [integrationGID, setIntegrationGID] = useState<string>();

	useRequiresAdmin();

	const { data, loading } = useQuery(integration.actions?.list, {
		variables: { params: {} },
	});

	const onModalClose = () => {
		setModalOpen(false);
	};

	const columnHelper = createColumnHelper<PlaceholderType>();
	const columns = useMemo(() => {
		const editColumn = columnHelper.accessor("_id", {
			id: "actions",
			header: "",
			enableSorting: false,
			cell: ({ row }) => (
				<Button
					label="Edit"
					prominence="secondary"
					onClick={() => {
						setIntegrationGID(row.original._id);
						setModalOpen(true);
					}}
				/>
			),
		});

		return [
			...integration.listFields.map((fieldName) => {
				const field = integration.fields.find(
					(field) => field.name === fieldName
				);

				if (!field) {
					throw new Error(`Column definition not found for ${fieldName}`);
				}

				return columnHelper.accessor(field.name as DeepKeys<PlaceholderType>, {
					id: field.name,
					header: field.label,
					enableSorting: false,
					cell: ({ row }) => (
						<div>{row.original[field.name as keyof PlaceholderType]}</div>
					),
				});
			}),
			editColumn,
		];
	}, [integration.listFields, integration.fields, columnHelper]);

	return (
		<>
			<Breadcrumbs />
			<SettingsPageTitle>
				{integration.name + " Integrations"}
			</SettingsPageTitle>
			<Stack spacing="larger">
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Text>{integration.listPageDescription}</Text>
					<Button
						label="Create Integration"
						prominence="primary"
						onClick={() => {
							setIntegrationGID(undefined);
							setModalOpen(true);
						}}
					/>
				</Stack>
				{loading ?
					<CenteredCircularProgress />
				:	<DataTablePrototype
						columns={columns}
						getRowId={getRowId}
						data={data?.listEverbridgeIntegrations ?? []}
						showGlobalFilter={false}
					/>
				}
				<GenericIntegrationModal
					integration={integration}
					integrationGID={integrationGID}
					open={modalOpen}
					onClose={onModalClose}
				/>
			</Stack>
		</>
	);
}

function getRowId(row: PlaceholderType) {
	return row._id;
}

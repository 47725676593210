import { Integration } from "../generic/Integration";
import { GetIntegration } from "./getGenetecIntegration";
import { ListIntegrations } from "./listGenetecIntegrations";

export const genetecIntegration: Integration = {
	type: "genetec",
	name: "Genetec",
	cardDescription: "Manage your Genetec Integrations",
	listPageDescription:
		"Add, edit, or delete your Genetec Integrations from this page",
	icon: "genetec",
	fields: [
		{
			label: "ID",
			name: "_id",
			required: {
				create: true,
				update: true,
			},
			secret: false,
			type: "string",
		},
		{
			label: "Name",
			name: "name",
			required: {
				create: true,
				update: true,
			},
			secret: false,
			type: "string",
		},
		{
			label: "Url",
			name: "url",
			required: {
				create: true,
				update: true,
			},
			secret: false,
			type: "string",
		},
		{
			label: "Username",
			name: "username",
			required: {
				create: true,
				update: true,
			},
			secret: false,
			type: "string",
		},
		{
			label: "Password",
			name: "password",
			required: {
				create: true,
				update: false,
			},
			secret: true,
			type: "string",
		},
	],

	listFields: ["_id", "name", "url"],

	actions: {
		list: ListIntegrations,
		get: GetIntegration,
	},

	featureFlag: "genetec",
};

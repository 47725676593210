import { graphql } from "gql/apollo";

export const GetIntegration = graphql(/* GraphQL */ `
	query GetEverbridgeIntegration($gid: ObjectId!) {
		integration: getEverbridgeIntegration(params: { _id: $gid }) {
			_id
			name
			url
		}
	}
`);
